import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import AddAddOnPage from "../pages/product/AddAddOnPage";
import AddCouponPage from "../pages/product/AddCouponPage";
import AddCustomerPage from "../pages/AddCustomerPage";
import AddInvoicePage from "../pages/invoice_page/AddInvoicePage";
import AddProductPlanPage from "../pages/product/AddProductPlanPage";
import AddSubscriptionPage from "../pages/subscription_page/AddSubscriptionPage";
import CustomerDetailsPage from "../pages/customer-page/CustomerDetailsPage";
import CustomersPage from "../pages/customer-page/CustomersPage";


import InvoiceDetailsPage from "../pages/invoice_page/InvoiceDetailsPage";
import NotFoundPage from "../pages/NotFoundPage";
import ProductPage from "../pages/product/ProductPage";
import SubscriptionDashboard from "../pages/subscription_page/SubscriptionDashboard";
import SubscriptionDetailsPage from "../pages/subscription_page/SubscriptionDetailsPage";
import SubscriptionPage from "../pages/subscription_page/SubscriptionPage";
import { PrivateRoute } from "./PrivateRoutes";
import PaymentReceivedPage from "../pages/payment_receive/PaymentReceivedPage";
import AddPaymentReceived from "../pages/payment_receive/AddPaymentReceived";
import PaymentReceivedDetailsPage from "../pages/payment_receive/PaymentReceivedDetailsPage";
import InvoicePage from "../pages/invoice_page/InvoicePage";
import SalesOrderPage from "../pages/sales_order/SalesOrderPage";
import AddSalesOrderPage from "../pages/sales_order/AddSalesOrderPage";
import SalesOrderDetailsPage from "../pages/sales_order/SalesOrderDetailsPage";
import CorporatePage from "../pages/corprate_page/CorporatePage";
import CorporateDetailsPage from "../pages/corprate_page/CorporateDetailsPage";
import AddCorporatePage from "../pages/corprate_page/AddCorporatePage";
import CustomerApprovalPage from "../pages/customer_approval/customerApprovalPage";
import CustomerApproveDetails from "../pages/customer_approval/customerApprovedetails";
import VoucherCorporateMainPage from "../pages/vouchers-corporate/VoucherCorporateMainPage";
import AddCorporateVoucherPage from "../pages/vouchers-corporate/AddCorporateVoucherPage";
import VoucherCorDetailsPage from "../pages/vouchers-corporate/VoucherCorDetailsPage";
import VoucherOfflinePage from "../pages/voucher-offline/VoucherOfflinePage";
import OfflineVoucherRedeem from "../pages/voucher-offline/OfflineVoucherRedeem";
import OfflineDetailsPage from "../pages/voucher-offline/OfflneDetailsPage";
import CorporateApprovalPage from "../pages/corporate_approval/CorporateApprovalPage";
import CorporateAppDetailsPage from "../pages/corporate_approval/CorperateAppDetailsPage";
import RatingMainPage from "../pages/rating/RatingMainPage";
import RatingDetailsPage from "../pages/rating/RatingDetailsPage";
import RewardCenterSetup from "../pages/reward_center/RewardCenterSetup";
import RewardHistoryPage from "../pages/reward_history/RewardHistoryPage";
import RewardHistoryDetails from "../pages/reward_history/RewardHistorydetails";
import RewardRedeemPage from "../pages/reward_redeeem/RewardRedeemPage";
import CarrierBagPage from "../pages/carreer_bag/CarrierBagPage";
import CarrierDetailsPage from "../pages/carreer_bag/CarrierPageDetails";
import CarrierBagAddNewPage from "../pages/carreer_bag/CarrierBadAddNewPage";
import VouchersMainPage from "../pages/vouchers/VoucherMainPage";
import HomePageFeatureDetail from "../pages/home_page_feature/HomePageFeatureDetails";
import VoucherDetailContent from "../pages/vouchers/VoucherDetailsContent";
import VoucherDetailsPage from "../pages/vouchers/VoucherDetailPage";

function SubscriptionRoutes() {
    return ( 
        <>
      <Userlayout>
        <Routes>
       <Route  path='customers' element={
                <PrivateRoute><CustomersPage /></PrivateRoute>}>
          </Route>

            {/* customer page children routes */}
            <Route exact  path='/customers/customer-details/:customer_id' element={
                  <PrivateRoute>  <CustomerDetailsPage />  </PrivateRoute>}>
            </Route>
          
      <Route  path='/customers/add_customer' element={
            <PrivateRoute> <AddCustomerPage /> </PrivateRoute>}>
      </Route>

        
          <Route exact  path='/dashboard' element={
                <PrivateRoute> <SubscriptionDashboard /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/dashboard/customer-details/:customer_id' element={
                  <PrivateRoute> < CustomerDetailsPage/>  </PrivateRoute>}>
            </Route>

          <Route exact  path='/products' element={
                <PrivateRoute> <ProductPage /> </PrivateRoute>}>
          </Route>  


          <Route exact path='/grocedy-rating' element={
                  <PrivateRoute> <RatingMainPage /> </PrivateRoute>}>
            </Route>
         
            <Route exact path='/grocedy-rating/details/:id' element={
                  <PrivateRoute> <RatingDetailsPage /> </PrivateRoute>}>
            </Route>



          <Route exact path='/corporate-approval' element={
                  <PrivateRoute> <CorporateApprovalPage /> </PrivateRoute>}>
            </Route>
            {/* <Route exact path='/corporate/add-new' element={
                  <PrivateRoute> <AddCorporatePage /> </PrivateRoute>}>
            </Route> */}
            <Route exact path='/corporate-approval/details/:corporate_id' element={
                  <PrivateRoute> <CorporateAppDetailsPage /> </PrivateRoute>}>
            </Route>



          <Route exact  path='/corporate' element={
                <PrivateRoute>   <CorporatePage />  </PrivateRoute>}>
          </Route> 

          <Route exact  path='/corporate/add-new' element={
                <PrivateRoute>   <AddCorporatePage />  </PrivateRoute>}>
          </Route>  

          <Route  path='/corporate/details/:corporate_id' element={
                  <PrivateRoute>  <CorporateDetailsPage /> </PrivateRoute>}>
            </Route>


            <Route exact  path='/customer-approval' element={
                <PrivateRoute>   <CustomerApprovalPage />  </PrivateRoute>}>
          </Route> 

            <Route exact  path='/customer-approval/details/:customer_id' element={
                <PrivateRoute>   <CustomerApproveDetails />  </PrivateRoute>}>
          </Route> 
         
                  {/* product Page */}
          <Route exact  path='/products/add-plan/:product_id' element={
                <PrivateRoute> <AddProductPlanPage /> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/products/add-addon/:product_id' element={
                <PrivateRoute> <AddAddOnPage /> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/products/edit-addon/:addon_id_edit/:product_id_edit' element={
                <PrivateRoute> <AddAddOnPage /> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/products/add-coupon/:product_id' element={
                <PrivateRoute> <AddCouponPage /> </PrivateRoute>}>
          </Route>  


          <Route exact  path='/products/edit-coupon/:coupon_id_edit/:product_id_edit' element={
                <PrivateRoute> <AddCouponPage /> </PrivateRoute>}>
          </Route>  

                  {/* product Page end */}


          <Route exact  path='/subscriptions' element={
                <PrivateRoute> <SubscriptionPage /> </PrivateRoute>}>
          </Route>  
          <Route exact  path='/subscriptions/add-new' element={
                <PrivateRoute> <AddSubscriptionPage /> </PrivateRoute>}>
          </Route>    
          <Route exact  path='/subscriptions/add-new' element={
                <PrivateRoute> <AddSubscriptionPage /> </PrivateRoute>}>
          </Route>    
          

          <Route exact  path='/subscriptions/details/:sub_id' element={
                <PrivateRoute> <SubscriptionDetailsPage /> </PrivateRoute>}>
          </Route>  


          <Route  path='/redeem-offline-voucher' element={
                  <PrivateRoute>  < VoucherOfflinePage/> </PrivateRoute>}>
            </Route>

            <Route  path='/redeem-offline-voucher/add-new' element={
                  <PrivateRoute>  < OfflineVoucherRedeem/> </PrivateRoute>}>
            </Route>
            
            <Route  path='/redeem-offline-voucher/details/:id' element={
                  <PrivateRoute>  < OfflineDetailsPage/> </PrivateRoute>}>
            </Route>


            {/* Voucher Setup */}
          <Route  path='/Setup' element={
                  <PrivateRoute>  < RewardCenterSetup/> </PrivateRoute>}>
            </Route>

          <Route  path='/reward' element={
                  <PrivateRoute>  < RewardHistoryPage/> </PrivateRoute>}>
            </Route>

          <Route  path='/reward/details/:id' element={
                  <PrivateRoute>  < RewardHistoryDetails/> </PrivateRoute>}>
            </Route>

           {/* Reward redeem */}
           <Route  path='/carrier-bag-return' element={
                  <PrivateRoute>  < CarrierBagPage/> </PrivateRoute>}>
            </Route>

           <Route  path='/carrier-bag-return/details/:id' element={
                  <PrivateRoute>  < CarrierDetailsPage/> </PrivateRoute>}>
            </Route>


           <Route  path='/carrier-bag-return/add-new' element={
                  <PrivateRoute>  < CarrierBagAddNewPage/> </PrivateRoute>}>
            </Route>



            <Route  path='/reward-redeem' element={
                  <PrivateRoute>  < RewardRedeemPage/> </PrivateRoute>}>
            </Route>


       
          <Route exact  path='/invoices' element={
                <PrivateRoute> <InvoicePage /> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/invoices/details/:invoice_id' element={
                 <PrivateRoute> <InvoiceDetailsPage /> </PrivateRoute>}> 
          </Route> 

          <Route exact  path='/invoices/add-invoice' element={
                <PrivateRoute> <AddInvoicePage /> </PrivateRoute>}>
          </Route> 

          <Route exact  path='/payment-received' element={
                <PrivateRoute> <PaymentReceivedPage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/payment-received/add-payment-received' element={
                <PrivateRoute> <AddPaymentReceived /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/payment-received/details/:payment_ref_id' element={
                <PrivateRoute> <PaymentReceivedDetailsPage /> </PrivateRoute>}>
          </Route> 

          <Route  path='/sales-order' element={
                  <PrivateRoute>  <SalesOrderPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/sales-order/add-new' element={
                  <PrivateRoute>  <AddSalesOrderPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/sales-order/details/:sales_id' element={
                  <PrivateRoute>  <SalesOrderDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route exact  path='/corporate' element={
                <PrivateRoute> <CorporatePage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/corporate/add-new' element={
                <PrivateRoute> <AddCorporatePage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/corporate/details/:corporate_id' element={
                <PrivateRoute> <CorporateDetailsPage /> </PrivateRoute>}>
          </Route>

            {/* Corporate Voucher */}
          <Route  path='/corporate-voucher' element={
                  <PrivateRoute>  < VoucherCorporateMainPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/corporate-voucher/add-new' element={
                  <PrivateRoute>  < AddCorporateVoucherPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/corporate-voucher/details/:id' element={
                  <PrivateRoute>  < VoucherCorDetailsPage/> </PrivateRoute>}>
            </Route>
            {/* Corporate Voucher end */}


            <Route  path='/voucher' element={
                  <PrivateRoute>  < VouchersMainPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/voucher/details/:id' element={
                  <PrivateRoute>  < VoucherDetailsPage/> </PrivateRoute>}>
            </Route>


        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default SubscriptionRoutes;