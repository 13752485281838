import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { accountService } from "../services/accountService";
import LogicContext from "../Context/LogicContext";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
// import "../../pages/ItemPage.css";


import { Grid } from "@mui/material";
import GeneralModal from "../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ChartOfAccountTable from "../components/ChartOfAccountTable";
import {SetChartList, GetChartList, SetChartTableFilter, GetChartTableFilter,
SetChartTableHead, GetChartTableHead} from '../store/chartOfAccountAtom';
import ActionPaneComponent from "../helpers/ActionPaneComponent";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";


const ChartOfAccountPage = () => {
  const logicContext = useContext(LogicContext);
    const subChartList = SetChartList();
    const getChartList = GetChartList();
    const setChartFilter = SetChartTableFilter();
    const chartFilter = GetChartTableFilter();
    const setChartTableHead = SetChartTableHead();
    const myPromiseModal = createModal(GeneralModal);
    // const masterData = GetSubMasterData();
  
    const [itemSelected, setItemSelected] = useState([]);
    const [tableData, setTableData] = useState(getChartList);
    const [bulkActionData, setBulkActionData] = useState(chartFilter);



  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const loadItemsCallback =  useCallback(()=> {
    accountService.getChartOfAccount().then(res => {
      if(res?.data !== undefined){
          setTableData(res.data['Chart of Accounts']);
          subChartList(res.data['Chart of Accounts']);
          setChartFilter(res.data['Filtering Criteria']);
          setBulkActionData(res.data['Filtering Criteria']);
          setChartTableHead(res.data['Display Column']);
      }
  });

  },[setChartFilter, setChartTableHead, subChartList])





  useEffect(() => {
   if( getChartList.length < 1){
    loadItemsCallback();
   }
   
  }, [getChartList.length, loadItemsCallback]);






  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

 


  const resetselecteditems = () => {
    setItemSelected([]);
  };

  const onSelectBulkAction = (action)=> {
    const option = action.target.value;
    if(option === ''){
        return setTableData(getChartList);
    }
    var newList = getChartList.filter((item)=> item.Account_Status_Id === option );
    setTableData(newList);
  }


  async function bulkActions(message, option) {
    await myPromiseModal({ title: message + '?',
      body: `Do you want to  ${message}?`,showInput:false, placeHolder:''
    }).then((result) => {
     
      if(result.decision === true){
        let temp = '';
        itemSelected.map(item => {
          temp += `<a> <account_id> ${item.Account_Id} </account_id> </a>`
        });
  
        let xml_string = `<root> ${temp} </root>`;
        accountService.updateAccountStatus({xml_string, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
           return  loadItemsCallback();
          }
      }).catch((err)=> {
        logicContext.showToast('Error', 'error');
      });
      }
  
      
   
    });


  }


    return ( 
       
        <>
        {itemSelected?.length > 0 ? (
          <div className="row">
            <div className="col col-md-4">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#E5E5E5",
                    border: "none",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "45px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>
  
                <Dropdown.Menu>
               
                      <Dropdown.Item  onClick={() => bulkActions('Mark selection(s) as Active', 4)}>
                        Mark as Active
                      </Dropdown.Item>
               
                      <Dropdown.Item  onClick={() => bulkActions('Mark selection(s) as InActive', 5)}>
                        Mark as InActive
                      </Dropdown.Item>
                      <Dropdown.Item  onClick={() => bulkActions('Delete selection(s) ', 3)}>
                        Delete
                      </Dropdown.Item>
                   
                </Dropdown.Menu>
              </Dropdown>

           
            </div>
  
            <div className="col col-md-4 offset-md-4 text-center">
              <button onClick={resetselecteditems} className="btn btn-danger">
                X
              </button>
            </div>
          </div>
        ) : (
          <Grid container spacing={10}>
            <Grid item xs={4}>
              <div className="flex ">
                <div className="flex mt-1  rounded  pr-4 -ml-2 ">
                <ActionPaneComponent bulkActionData={bulkActionData}
                onSelectBulkAction={onSelectBulkAction} />
                   <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-1"
                  onClick={() => loadItemsCallback()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
                </div>
  
           
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className="flex  justify-end">
          
  
                <Link
                  to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`}
                >
                  <button
                    style={{ backgroundColor: "#476EF8" }}
                    className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                  >
                    ADD NEW ACCOUNT
                  </button>
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
  
        <section className="mt-5   w-full">
          <ChartOfAccountTable
            tableData={tableData}
            selectedTableItems={selectedTableItems}
          ></ChartOfAccountTable>
        </section>
      </>
     );
}
 
export default ChartOfAccountPage;